import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enTranslations } from 'common/translations/en';
import { frTranslations } from 'common/translations/fr';

const DEFAULT_LNG = 'en';

i18n
  // .use(XHR)
  .use(initReactI18next)
  .init({
    lng: DEFAULT_LNG,
    nsSeparator: '##',
    keySeparator: '',

    resources: {
      en: {
        translation: enTranslations,
      },
      fr: {
        translation: frTranslations,
      },
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}',
    },

    fallbackLng: {
      default: [DEFAULT_LNG],
    },
  });

export default i18n;
