import { Box } from '@mui/material';
import { theme } from 'common/constants/theme';
import { ReactElement, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Styles } from 'common/types/styles';

const getStyles = (): Styles => ({
  container: {
    display: 'flex',
    minHeight: '100%',
    backgroundColor: theme.palette.background.default,
  },
});

export function Layout(): ReactElement {
  const location = useLocation();

  const topAnchorRef = useRef<HTMLDivElement>();

  const styles = getStyles();

  useEffect(() => {
    if (!topAnchorRef.current) return;

    setTimeout(() => {
      topAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, [topAnchorRef, location.pathname]);

  return (
    <Box sx={styles.container} className="con">
      <Outlet />
    </Box>
  );
}
