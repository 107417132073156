import { Box, Button, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { HashContainer } from 'common/ui/containers/hash-container';
import { StatusDropdown } from 'common/ui/inputs/status-dropdown';
import WorkIcon from '@mui/icons-material/Work';
import {
  Fragment,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

interface JobEntry {
  data?: Array<{
    type: 'link';
    value: string;
  }>;
  name: string;
  loading: boolean;
  error?: string;
}

const getStyles = (triggered: boolean): Styles => ({
  statusesButton: {
    position: 'fixed',
    bottom: 16,
    left: 16,
    zIndex: 9999,
    opacity: 0.5,
    visibility: triggered ? 'visible' : 'hidden',
  },
  statusesContainer: {
    width: 600,
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    overflowY: 'scroll',
    padding: 2,
    boxSizing: 'border-box',
  },
  linksContainer: { gap: 0.5 },
  link: {
    fontSize: 12,
    width: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
});

const context = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleTriggered: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createJobEntry: (id: string, name: string) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateJobEntry: (id: string, entry: Partial<JobEntry>) => undefined,
});

export function StatusesContainer({ children }: PropsWithChildren): ReactElement {
  const [triggered, setTriggered] = useState<boolean>(false);
  const [jobs, setJobs] = useState<Record<string, JobEntry>>({});
  const [statusesOpen, setStatusesOpen] = useState<boolean>(false);

  const styles = getStyles(triggered);

  const statusesBtnRef = useRef<HTMLButtonElement>();

  const handleStatusesOpen = useCallback(() => {
    setStatusesOpen(true);
  }, []);

  const handleStatusesClose = () => {
    setStatusesOpen(false);
  };

  const createJobEntry = useCallback((id: string, name: string) => {
    setJobs(jobs => ({ ...jobs, [id]: { loading: true, name } }));
  }, []);

  const updateJobEntry = useCallback((id: string, entry: Partial<JobEntry>) => {
    setJobs(jobs => ({ ...jobs, [id]: { ...jobs[id], ...entry } }));
  }, []);

  const handleTriggered = useCallback(() => {
    setTriggered(true);
    handleStatusesOpen();
  }, [handleStatusesOpen]);

  const getStatusValue = (id: string) => {
    const entry = jobs[id];

    if (entry.loading) return 'Loading';
    if (entry.error) return 'Failed';

    return 'Completed';
  };

  const value = useMemo(() => {
    return { handleTriggered, createJobEntry, updateJobEntry };
  }, [createJobEntry, handleTriggered, updateJobEntry]);

  return (
    <context.Provider value={value}>
      {children}
      <Button
        sx={styles.statusesButton}
        color="primary"
        variant="contained"
        ref={statusesBtnRef}
        onClick={handleStatusesOpen}
        size="small"
      >
        <WorkIcon fontSize="small" />
        Jobs Statuses
      </Button>
      <Popover
        onClose={handleStatusesClose}
        open={statusesOpen}
        anchorEl={statusesBtnRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={styles.statusesContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job Name</TableCell>
                <TableCell>Job ID</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(jobs).map(([id, entry]) => (
                <Fragment key={id}>
                  <TableRow>
                    <TableCell>{entry.name}</TableCell>
                    <TableCell>
                      <HashContainer value={id} disableCopy />
                    </TableCell>
                    <TableCell>
                      <StatusDropdown value={getStatusValue(id)} showExpand={false} disabled />
                    </TableCell>
                  </TableRow>
                  {entry.data?.filter(d => d.type === 'link') && (
                    <TableRow sx={styles.linksContainer}>
                      <TableCell colSpan={3}>
                        {entry.data
                          ?.filter(d => d.type === 'link')
                          .map(d => (
                            <Typography component="a" href={d.value} sx={styles.link}>
                              {d.value}
                            </Typography>
                          ))}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Popover>
    </context.Provider>
  );
}

export function useStatusesContext() {
  const ctx = useContext(context);
  return ctx;
}
