import React, { FunctionComponent } from 'react';

export interface Route {
  path: string;
  preview: string;
  component: FunctionComponent;
  isAvailable: boolean;
  hiddenLayout?: boolean;
}

const Overview = React.lazy(() => import('modules/dashboard'));
const Auth = React.lazy(() => import('modules/auth'));

export const getProtectedRoutes = (): Array<Route> => [
  {
    path: '/',
    preview: 'Overview',
    component: Overview,
    isAvailable: true,
  },

  {
    path: '/auth',
    preview: 'Auth',
    component: Auth,
    isAvailable: true,
  },
];
