import ReactDOM from 'react-dom/client';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { BackendErrorToast, RuntimeError } from 'root/errors';
import { theme } from 'common/constants/theme';
import * as Sentry from '@sentry/react';
import { JwtAuth } from 'root/jwt-auth';
import { DemoContext } from 'common/ui/containers/demo-context';
import { StatusesContainer } from 'common/ui/shared/statuses-container';
import { AxiosError } from 'axios';
import { BackendErrorResponse } from 'common/types/common';
import { MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from './root';
import './config/i18n';
import './config/sentry';

const root = ReactDOM.createRoot(document.getElementById('root'));

const handleReqError = (error: unknown) => {
  if (error instanceof AxiosError) {
    const backendError = error as AxiosError<BackendErrorResponse>;
    backendError.response.data?.errors?.forEach(error => {
      toast.error(<BackendErrorToast error={error} />, { duration: 10000 });
    });
  }
};

const mutationCache = new MutationCache({
  onError: error => {
    handleReqError(error);
  },
});

root.render(
  <Sentry.ErrorBoundary fallback={<RuntimeError />}>
    <ThemeProvider theme={theme}>
      <Toaster toastOptions={{ style: { maxWidth: '30vw', paddingRight: 0 } }} />
      <Router>
        <QueryClientProvider
          client={
            new QueryClient({
              mutationCache,
              defaultOptions: { queries: { refetchOnWindowFocus: false } },
            })
          }
        >
          <JwtAuth>
            <StatusesContainer>
              <DemoContext>
                <App />
              </DemoContext>
            </StatusesContainer>
          </JwtAuth>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  </Sentry.ErrorBoundary>
);
