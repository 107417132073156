import { LC_TOKEN, LC_TOKEN_EXPIRATION } from 'common/constants/lc';
import { useOAuthLinkMutation, useRefreshTokenMutation } from 'common/hooks/api/mutations/use-auth-mutations';
import { useMeQuery } from 'common/hooks/api/queries/use-auth-queries';
import { add, isBefore, toDate } from 'date-fns';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingContainer } from 'root/layout/loading-container';

const jwtAuthCtx = createContext({
  user: null,
  loading: true,
});

export function JwtAuth({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();

  const token = useMemo(() => {
    const token = searchParams.get('access_token') || localStorage.getItem(LC_TOKEN);
    localStorage.setItem(LC_TOKEN, token);
    searchParams.delete('access_token');
    setSearchParams(searchParams);
    return token;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const { data: userData, isFetching: isUserFetching } = useMeQuery(Boolean(token));

  const { mutate: mutateOAuthLink } = useOAuthLinkMutation();
  const { mutate: mutateRefresh } = useRefreshTokenMutation();

  const refreshInterval = useRef<number>();

  const value = useMemo(() => {
    return {
      user: userData,
      loading: isUserFetching,
    };
  }, [isUserFetching, userData]);

  const oauthRedirect = useCallback(() => {
    mutateOAuthLink(null, {
      onSuccess: ({ data }) => {
        window.location.href = data.link;
      },
    });
  }, [mutateOAuthLink]);

  useLayoutEffect(() => {
    const expiresAt = searchParams.get('expires_at') || localStorage.getItem(LC_TOKEN_EXPIRATION);
    localStorage.setItem(LC_TOKEN_EXPIRATION, expiresAt);
    searchParams.delete('expires_at');
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) {
      oauthRedirect();
      return;
    }

    if (!userData) {
      if (isUserFetching) return;
      oauthRedirect();
      return;
    }

    setIsAuthenticated(true);
  }, [isUserFetching, token, userData, oauthRedirect]);

  useEffect(() => {
    if (!isAuthenticated || !userData) return;

    window.clearInterval(refreshInterval.current);

    refreshInterval.current = window.setInterval(() => {
      // eslint-disable-next-line no-useless-return
      if (isBefore(new Date(), add(toDate(Number(localStorage.getItem(LC_TOKEN_EXPIRATION))), { minutes: 5 }))) return;

      mutateRefresh(null, {
        onSuccess: ({ data }) => {
          if (data.success && data.access_token) {
            localStorage.setItem(LC_TOKEN, data.access_token);
            localStorage.setItem(LC_TOKEN_EXPIRATION, data.expires_at);
          } else {
            window.location.href = data.link;
          }
        },
        onError: () => {
          oauthRedirect();
        },
      });
    }, 1000);
  }, [isAuthenticated, mutateRefresh, oauthRedirect, userData]);

  return (
    <jwtAuthCtx.Provider value={value}>
      {isUserFetching || !isAuthenticated ? <LoadingContainer type="fullscreen" /> : children}
    </jwtAuthCtx.Provider>
  );
}

export function useJwtAuthContext() {
  const ctx = useContext(jwtAuthCtx);

  return ctx;
}
