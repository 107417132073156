import { User } from 'common/types/common';

class UserService {
  private permissions: Array<string> = [];

  private roles: Array<string> = [];

  private level = '';

  private user: User = null;

  private retailer = '';

  setPermissions = (permissions: Array<string>) => {
    this.permissions = permissions;
  };

  setRoles = (roles: Array<string>) => {
    this.roles = roles;
  };

  setUser = (user: User) => {
    this.user = user;
  };

  getUser = () => {
    return this.user;
  };

  ensurePermissions = (permissions: Array<string>) => {
    if (!this.permissions?.length) return false;
    return permissions.every(requestedPermission =>
      this.permissions.some(permission => permission === requestedPermission)
    );
  };
}

export const userService = new UserService();
