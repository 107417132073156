import { useMutation } from '@tanstack/react-query';
import { authApi } from 'common/services/api/auth/auth-api.service';

export function useOAuthLinkMutation() {
  const mutation = useMutation({
    mutationFn: authApi.getOAuthLink,
  });

  return mutation;
}

export function useRefreshTokenMutation() {
  const mutation = useMutation({
    mutationFn: authApi.refreshToken,
    retry: false,
  });

  return mutation;
}

export function useRevokeTokenMutation() {
  const mutation = useMutation({
    mutationFn: authApi.revokeToken,
  });

  return mutation;
}
