import { getProtectedRoutes } from 'common/constants/routes';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'root/layout';
import { LoadingContainer } from 'root/layout/loading-container';
import * as Sentry from '@sentry/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  const protectedRoutes = getProtectedRoutes().filter(route => route.isAvailable && !route.hiddenLayout);

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        {protectedRoutes.map(route => (
          <Route
            path={route.path}
            key={route.path}
            element={
              <Suspense fallback={<LoadingContainer type="fullwidth" />}>
                <route.component />
              </Suspense>
            }
          />
        ))}
      </Route>
    </SentryRoutes>
  );
}
