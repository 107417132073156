import { useQuery } from '@tanstack/react-query';
import { authApi } from 'common/services/api/auth/auth-api.service';

export enum AuthQueryKeys {
  Me = '[Auth] Me',
}

export function useMeQuery(enabled: boolean) {
  const query = useQuery({
    queryKey: [AuthQueryKeys.Me],
    queryFn: async () => {
      const { data } = await authApi.getMe();
      return data;
    },
    retry: false,
    enabled,
  });

  return query;
}
