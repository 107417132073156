import { AxiosResponse } from 'axios';
import { User } from 'common/types/common';
import { ApiService } from '../api.service';
import { GetOAuthLinkResponse, PostRefreshTokenResponse } from './auth-api.types';

class AuthApiService extends ApiService {
  getOAuthLink = (): Promise<AxiosResponse<GetOAuthLinkResponse>> => {
    return this.get('/v1/auth/google/link');
  };

  refreshToken = (): Promise<AxiosResponse<PostRefreshTokenResponse>> => {
    return this.post('/v1/auth/google/token/refresh');
  };

  revokeToken = (): Promise<AxiosResponse<User>> => {
    return this.post('/v1/auth/google/token/revoke');
  };

  getMe = (): Promise<AxiosResponse> => {
    return this.get('/v1/auth/me');
  };
}

export const authApi = new AuthApiService(process.env.REACT_APP_API);
